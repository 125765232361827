import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import blogPostStyles from './blog-post.module.css';

import SiteMetadata from '../components/site-metadata/site-metadata';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

export const query = graphql`
  query($id: String!) {
    prismicBlogPost(id: { eq: $id }) {
      id
      first_publication_date
      data {
        displayed_publish_date
        title {
          text
        }
        content {
          html
        }
        excerpt {
          text
        }
        main_image {
          thumbnails {
            hero_banner {
              url
            }
          }
        }
      }
    }
  }
`;

export default function BlogPost({ data }) {
  const post = data.prismicBlogPost;
  const publishedDateField = post.data.displayed_publish_date
    ? post.data.displayed_publish_date
    : post.first_publication_date;

  const publishedDate = new Date(publishedDateField);

  const ldJson = {
    '@context': 'https://schema.org/',
    '@type': 'BlogPosting',
    headline: post.data.title.text,
    image: [post.data.main_image.thumbnails.hero_banner.url],
    datePublished: publishedDate.toISOString(),
    author: [
      {
        '@type': 'Person',
        name: 'Tom Seldon',
      },
    ],
  };

  return (
    <div>
      <SiteMetadata
        title={post.data.title.text}
        image={post.data.main_image.thumbnails.hero_banner.url}
        description={post.data.excerpt.text}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <Header activePageName="race-report" />

      <img
        className={blogPostStyles.image}
        src={post.data.main_image.thumbnails.hero_banner.url}
        alt={post.data.title.text}
      />

      <div className={blogPostStyles.content}>
        <h1 className={blogPostStyles.title}>{post.data.title.text}</h1>

        <span className={blogPostStyles.publishedDate}>
          {publishedDate.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </span>

        {/* rome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
        <div dangerouslySetInnerHTML={{ __html: post.data.content.html }} />
      </div>

      <SponsorsFooter />
      <Footer />
    </div>
  );
}
